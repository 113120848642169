header .link-head div {
  padding: 10px;
  width: 40px;
}

header .link-head a {
  position: relative;
  z-index: 12;
  padding: 6px;
  /* border: 1px solid black; */
  width: calc(25% - 10px);
  text-align: center;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.7);
  color: rgb(182, 48, 61);
}

header .link-head a.active {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

header .link-head {
  margin-left: 100px;
  width: calc(100%-100px);
  display: flex;
}

header .logo {
  top: 0;
  width: 100px;
  height: 100px;
  z-index: 12;
  position: relative;
}

header .logo-head {
  display: flex;
  justify-content: space-between;
}

header .triangle {
  position: absolute;
  width: 100%;
  z-index: 11;
  border-top: 141px solid white;
  border-right: 100px solid transparent;
}

header .logo-wrapper {
  position: relative;
}

header .logo-head .text-center {
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 2px 2px black;
}

header .logo-head .text-center .underline {
  margin-top: -33px;
  /* border: 1px solid white; */
}

header .language {
  color: white;
  height: 100%;
  padding: 10px;
}

header .language a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
}

header .language a.active {
  font-weight: bolder;
}

.desktop {
  display: block !important;
  background-color: rgb(182, 48, 61);
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 141px;
}

.mobile {
  display: none !important;
}

@media (max-width: 768px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
    background: white;
    border-bottom: 2px solid rgb(182, 48, 61);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }

  .hamburger {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .logo-mobile-wrapper {
    text-align: center;
    margin: auto;
  }

  .logo-mobile-wrapper img {
    width: 75px;
    height: 75px;
  }

  .navlink.active {
    text-align: center;
    background-color: rgba(182, 48, 61, 1);
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 1s;
    max-height: 500px;
  }

  .navlink.active div {
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding: 10px 0;
  }

  .navlink.active div a {
    text-decoration: none;
    color: white;
  }
  .navlink.active div a.active {
    text-decoration: underline;
    font-weight: bolder;
  }

  .navlink.non-active {
    text-align: center;
    max-height: 0;
    overflow: hidden;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 1s;
  }

  .navlink.non-active div {
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding: 10px 0;
  }

  .navlink.non-active div a {
    text-decoration: none;
    color: white;
  }
  .navlink.non-active div a.active {
    text-decoration: underline;
    font-weight: bolder;
  }
}
