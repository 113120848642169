footer {
  background-color: rgb(182, 48, 61);
  text-align: center;
  color: white;
  margin-top: 20px;
}

footer .firstchild {
  background-color: rgb(39, 39, 39);
  padding: 20px 0;
}

footer .firstchild div {
  display: flex;
  justify-content: center;
}

.social {
  font-size: 2em;
}

.social i {
  margin: 0 10px;
  color: rgb(182, 48, 61);
}

.link a {
  text-decoration: none;
  color: white;
  margin: 10px 10px;
  font-weight: 600;
}

footer .secondchild {
  padding: 10px;
  width: fit-content;
  margin: auto;
}
