#landing {
  /* position: absolute;
  left: 0;
  top: 0; */
  /* max-width: 700px; */
  /* transform: translate(50%, 50%); */
  /* text-align: center;*/
  border: 1px solid rgba(0, 0, 0, 0.8);
  /* padding: 1.5em; */
  font-size: 1.5em;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

#landing .title {
  font-size: 2em;
}

#landing .link {
  margin-top: 10px;
  padding: 5px 10px;
}

#landing .link a {
  margin: 0 10px;
  text-decoration: none;
}

#landing .link button {
  width: 180px;
  height: 70px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

#landing .link button:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: rgb(182, 48, 61);
}

.fullscreen {
  width: 100%;
  height: 100vh;
  background-color: rgb(182, 48, 61);
  position: relative;
  overflow: hidden;
}

.logo-background {
  position: absolute;
  top: 0;
  left: 0;
  border-top: 375px solid white;
  border-right: 350px solid transparent;
}

.fullscreen img {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
}

#landing .detail {
  animation: 2s blink infinite;
  font-size: 1.5em;
  text-shadow: 1px 1px black;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@media (max-width: 768px) {
  .logo-background {
    border-top: 275px solid white;
    border-right: 450px solid transparent;
  }

  #landing .link button {
    width: 250px;
    margin-top: 10px;
  }
}

@media (max-width: 1024px) {
  #landing .link button {
    width: 220px;
    margin-top: 10px;
  }
}
