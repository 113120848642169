#loading {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  margin: auto;
  animation: 2s blink infinite;
}

#loading img {
  width: 100%;
  height: auto;
}

#loading span {
  color: rgb(182, 48, 61);
  /* animation: 2s blink infinite; */
}
