#mainlayout {
  margin: 0 50px;
  margin-top: 150px;
}

@media (max-width: 768px) {
  #mainlayout {
    margin-top: 100px;
  }
}
